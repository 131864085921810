export interface ReadOnlyProps {
    readOnly?: boolean; // Prop opcional read_only
}

export const CONSUMER_TYPES = {
    GUEST_IN_HOME: "guest_in_home",
    UPCOMING_ARRIVALS: "upcoming_arrivals",
    NEW_BOOKING: "new_booking"
};

export const CONSUMER_METHODS = ["email", "airbnbChat", "smsText"];

export const DEFAULT_SMS_MESSAGE = "IMPORTANT: Please check your email for a message from Vacasa regarding your reservation.";

export const defaultFilters = {
    title: "",
    is_active: true,
    pageNumber: 1,
    pageSize: 20,
};

export const timeToRedirectInMilliseconds = 5000;

export const UsersAllowed: string[] = [
    "abbey.holmes@vacasa.com",
    "nate.tomlinson@vacasa.com",
    "jasmine.kernes@vacasa.com",
    "andrea.engelland@vacasa.com",
    "alicia.eaton@vacasa.com",
    "bryan.jack@vacasa.com",
    "catherine.veitia@vacasa.com",
    "marcel.aigret@vacasa.com",
    "caty.haase@vacasa.com",
    "hilary.hockema@vacasa.com",
    "anna.bugbee@vacasa.com",
    "ashley.stout@vacasa.com",
    "derek.king@vacasa.com",
    "josh.wagner@vacasa.com",
    "miranda.hunter@vacasa.com",
    "lindsay.moore@vacasa.com",
    "sandie.caldwell@vacasa.com",
    "lorajean.harryman@vacasa.com",
    "jessica.carter@vacasa.com",
    "sahar.fallah@vacasa.com",
    "randy.peugh@vacasa.com",
    "pamela.howard@vacasa.com",
    "dustin.hopkins@vacasa.com",
    "kelly.cullens@vacasa.com",
    "oscar.yanez@vacasa.com",
    "cristian.barrera@vacasa.com",
    "robyn.barker@vacasa.com",
    "melissa.putnam@vacasa.com",
    "vicki.varmella@vacasa.com",
    "hailei.aberson-holford@vacasa.com",
    "hugo.ibanez@vacasa.com",
    "sergio.vergara@vacasa.com",
    "miguel.ramirez@vacasa.com",
    "pablo.baltierra@vacasa.com",
    "byron.oyarzun@vacasa.com"
];
