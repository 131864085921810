import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {defaultFilters} from "../constants";
import {Broadcast, BroadcastPagination, GetListResponse} from "@common/typing";
import {RootState} from "./store";
import {VacasaBroadcastService} from "@common/services";
import {setBroadcastErrorModalStatus} from "./broadcastSlice";

const getStateBasedFilters = (state: RootState) => ({
    ...defaultFilters,
    title: state.queue.filters.title.value,
    is_active: state.queue.filters.is_active.value,
    pageNumber: state.queue.filters.pageNumber.value,
    pageSize: state.queue.filters.pageSize.value,
});

export const getBroadcasts = createAsyncThunk("queue/getBroadcasts", async (undefined, {getState}): Promise<GetListResponse<Broadcast>> => {
    const state = getState() as RootState;
    const vacasaBroadCastService = VacasaBroadcastService.getInstance();
    const response = await vacasaBroadCastService.getBroadcasts({
        ...getStateBasedFilters(state),
    });
    return response;
});

type QueryFilters = {
    title: string;
    is_active: boolean | string;
    pageNumber: boolean | number;
    pageSize: boolean | number;
};

type QueueInitialState = {
    filters: {
        title: {
            value: string;
        }
        is_active: {
            value: boolean | string;
        },
        pageNumber: {
            value: boolean | number;
        },
        pageSize: {
            value: boolean | number;
        },
    }
    triggers: {
        clearFilters: number;
    },
    data: Broadcast[];
    pagination: BroadcastPagination;
    isFetching: boolean;
};

const initialState: QueueInitialState = {
    filters: {
        title: {
            value: defaultFilters.title
        },
        is_active: {
            value: defaultFilters.is_active
        },
        pageNumber: {
            value: defaultFilters.pageNumber
        },
        pageSize: {
            value: defaultFilters.pageSize
        },
    },
    triggers: {
        clearFilters: 0,
    },
    data: [],
    pagination: {
        pageNumber: defaultFilters.pageNumber,
        pageSize: defaultFilters.pageSize,
        count: 0,
    },
    isFetching: false,
};

const queueSlice = createSlice({
    name: "queue",
    initialState,
    reducers: {
        updateIsActiveFilter: (state, {payload}: {type: string; payload: boolean}) => {
            state.filters.is_active.value = payload;
        },
        updateTitleFilter: (state, {payload}: {type: string; payload: string}) => {
            state.filters.title.value = payload;
        },
        updateQueryFilters: (state, {payload}: {type: string; payload: QueryFilters}) => {
            state.filters.title.value = payload.title;
            state.filters.is_active.value = payload.is_active;
            state.filters.pageNumber.value = payload.pageNumber;
            state.filters.pageSize.value = payload.pageSize;
        },
        updateTablePagination: (state, {payload}:  {payload: BroadcastPagination}) => {
            state.pagination.pageNumber = payload.pageNumber;
            state.pagination.pageSize = payload.pageSize;
        },
        resetFilters: (state) => {
            state.filters = {
                ...initialState.filters,
            };
            state.triggers = {
                ...initialState.triggers,
                clearFilters: state.triggers.clearFilters + 1,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBroadcasts.pending, (state: QueueInitialState) => {
                state.isFetching = true;
            })
            .addCase(getBroadcasts.fulfilled, (state: QueueInitialState, {payload}) => {
                state.isFetching = false;
                state.data = payload?.data as any;
                state.pagination.count = payload?.meta?.pagination?.count;
            })
            .addCase(getBroadcasts.rejected, (state: QueueInitialState) => {
                state.isFetching = false;
                setBroadcastErrorModalStatus(true);
            })
    },
});

export const {updateQueryFilters, updateIsActiveFilter, resetFilters, updateTitleFilter, updateTablePagination} = queueSlice.actions;

export default queueSlice.reducer;
