import React, {useEffect, useLayoutEffect, useState, useRef } from "react";
import {BackSection} from "../back-section/BackSection";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {IncidentTitle} from "../incident-title/IncidentTitle";
import localStyles from "./BroadcastCase.module.scss";
import {Divider, Spin, Alert} from "antd";
import {resetFilters} from "../../store/queueSlice";
import {ImpactedUnits} from "../impacted-units/ImpactedUnits";
import {DatesEffective} from "../dates-effective/DatesEffective";
import {BroadcastReasons} from "../broadcast-reasons/BroadcastReasons";
import {MessageType} from "../message-type/MessageType";
import {Button} from "../inputs/button/Button";
import {CommunicationType} from "../communication-type/CommunicationType";
import {
    createBroadcast,
    getBroadcastReasons,
    setDeactivateModalStatus,
    setImpactedUnits,
    setImpactedUnitsDuplicated,
    setIsReview,
    setMessageType,
    getBroadcastById,
    cleanBroadcastData,
    cleanFormData,
    resetForm
} from "../../store/broadcastSlice";
import {BroadcastErrorModal, BroadcastSuccessModal, BroadcastInputErrorModal, DeactivateModal, UpdateDatesModal} from "../modals/Modals";
import {validateConsumerSelected, validateConsumersFields, validateInputs, getUserInfo} from "../../utils";
import {CONSUMER_TYPES, timeToRedirectInMilliseconds, ReadOnlyProps} from "../../constants";
import {BroadcastData} from "../../types/broadcast";
import {useNavigate, useParams} from "react-router-dom";
import {ValidationInputs, ValidationInputsType} from "../../types/general";

export const BroadcastCase = ({readOnly}: ReadOnlyProps): React.JSX.Element => {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const broadcastId = parseInt(id || "");

    //RKT State
    const dispatch = useAppDispatch();
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);
    const incidentTitle = useSelector((state: RootState) => state.broadcast.data.form.incidentTitle);
    const startDate = useSelector((state: RootState) => state.broadcast.data.form.startDate);
    const endDate = useSelector((state: RootState) => state.broadcast.data.form.endDate);
    const consumersSegmentationData = useSelector((state: RootState) => state.broadcast.data.form.consumerSegmentation);
    const guestInHome = useSelector((state: RootState) => state.broadcast.data.form.guest_in_home);
    const upcomingArrivals = useSelector((state: RootState) => state.broadcast.data.form.upcoming_arrivals);
    const newBooking = useSelector((state: RootState) => state.broadcast.data.form.new_booking);
    const impactedUnits = useSelector((state: RootState) => state.broadcast.data.form.impactedUnits.validUnits);
    const reasonsSelected = useSelector((state: RootState) => state.broadcast.data.form.reasonsSelected);
    const messageType = useSelector((state: RootState) => state.broadcast.data.form.messageType);
    const internalCommunication = useSelector((state: RootState) => state.broadcast.data.form.messageType.internal);
    const channelCommunication = useSelector((state: RootState) => state.broadcast.data.form.messageType.channel);
    const embedCommunication = useSelector((state: RootState) => state.broadcast.data.form.messageType.embed);
    const isSubmitting = useSelector((state: RootState) => state.broadcast.actions.submitting);
    const broadcastCreatedSuccessFully = useSelector((state: RootState) => state.broadcast.states.broadcastCreated);
    //broadcast detailed
    const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);
    const isLoading = useSelector((state: RootState) => state.broadcast.isLoading);

    //References and Errors
    const incidentTitleRef = useRef(null);
    const [incidentTitleError, setIncidentTitleError] = useState<string | null>(null);
    const dateRef = useRef(null);
    const [dateError, setDateError] = useState<string | null>(null);
    const impactedUnitsRef = useRef(null);
    const [impactedUnitsError, setImpactedUnitsError] = useState<string | null>(null);
    const internalCommunicationRef = useRef(null);
    const [internalCommunicationError, setInternalCommunicationError] = useState<string | null>(null);
    const channelCommunicationRef = useRef(null);
    const [channelCommunicationError, setChannelCommunicationError] = useState<string | null>(null);
    const embedCommunicationRef = useRef(null);
    const [embedCommunicationError, setEmbedCommunicationError] = useState<string | null>(null);
    const consumerSegmentationRef = useRef(null);
    const [consumerSegmentationError, setConsumerSegmentationError] = useState<string | null>(null);
    const [consumerSegmentationElementError, setConsumerSegmentationElementError] = useState<{message: string, consumer_segmentation: string} | null>(null);

    //Error Modal
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    //Success Modal
    const [successMessage, setSuccessMessage] = useState<string|null>(null);

    //Local State
    const [consumersSegmentation, setConsumersSegmentation] = useState<string[]>([]);

    const broadcastData: BroadcastData = {
        data: {
            type: "broadcast",
            attributes: {
                title: incidentTitle,
                has_internal_message: messageType?.internal?.checked,
                has_embed_message: messageType?.embed?.checked,
                has_channel_message: messageType?.channel?.checked,
                internal_message: messageType?.internal?.message,
                embed_message: messageType?.embed?.message,
                channel_message: messageType?.channel?.message,
                affect_guests_in_home: guestInHome.checked,
                affect_upcoming_arrivals: upcomingArrivals.checked,
                affect_new_bookings: newBooking.checked,
                start_date: startDate,
                end_date: endDate,
                is_active: true,
                consumer_segmentations: consumersSegmentation.map((consumer) => {
                    return {
                        type: consumer,
                        subject_line: "",
                        consumer_affected: "",
                        email: false,
                        email_message: "",
                        sms: false,
                        sms_message: "",
                        airbnb_chat: false,
                        airbnb_chat_message: "",
                    }
                }),
                units: impactedUnits?.map((unit) => unit?.id),
                reasons: reasonsSelected,
                created_by: getUserInfo()?.email,
            }
        }
    };

    const setInputErrors = (input: ValidationInputsType, message?: string) => {
      switch (input) {
        case ValidationInputs.title_of_incident:
          setIncidentTitleError(message || null);
          break;
        case ValidationInputs.impacted_units:
          setImpactedUnitsError(message || null);
          break;
        case ValidationInputs.starts_on:
          setDateError(message || null);
          break;
        case ValidationInputs.ends_on:
          setDateError(message || null);
          break;
        case ValidationInputs.internal_communication:
          setInternalCommunicationError(message || null);
          break;
        case ValidationInputs.channel_communication:
          setChannelCommunicationError(message || null);
          break;
        case ValidationInputs.embed_communication:
          setEmbedCommunicationError(message || null);
          break;
        case ValidationInputs.consumer_segmentation:
          setConsumerSegmentationError(message || null);
          break;
        default:
          break;
      }
    }

    // Scroll with top offset
    const scrollToMyRefWithOffset = (input: ValidationInputsType) => {
      
      let element = null;

      switch (input) {
        case ValidationInputs.title_of_incident:
          element = incidentTitleRef.current;
          break;
        case ValidationInputs.impacted_units:
          element = impactedUnitsRef.current;
          break;
        case ValidationInputs.starts_on:
          element = dateRef.current;
          break;
        case ValidationInputs.ends_on:
          element = dateRef.current;
          break;
        case ValidationInputs.internal_communication:
          element = internalCommunicationRef.current;
          break;
        case ValidationInputs.channel_communication:
          element = channelCommunicationRef.current;
          break;
        case ValidationInputs.embed_communication:
          element = embedCommunicationRef.current;
          break;
        case ValidationInputs.consumer_segmentation:
          element = consumerSegmentationRef.current;
          break;
        default:
          break;
      }

      if (element) {
        window.scroll({
          top: element.offsetTop - 100, // Scroll 100px above the element
          behavior: 'smooth'
        });
      }
    };

    const notValidReason = () => {
      setErrorMessage("Invalid broadcast reason");
    }

    const removeAllErrors = () => {
      // remove all errors when submit when isReview
      Object.keys(ValidationInputs).forEach(input => {
        setInputErrors(input as ValidationInputsType);
      })
      setConsumerSegmentationElementError(null);
    }

    const successRedirect = () => {
      setSuccessMessage(null);
      dispatch(setIsReview(false));
      dispatch(cleanBroadcastData());
      dispatch(cleanFormData());
      dispatch(resetForm());
      dispatch(resetFilters());
      window.scrollTo(0, 0);
      navigate("/queue");
    }

    //Handlers
    const handleClick = async () => {
        const requiredInputs = {
            title_of_incident: incidentTitle,
            impacted_units: impactedUnits,
            starts_on: startDate,
            ends_on: endDate,
        }

        removeAllErrors();

        const validateRequiredInputs = validateInputs(requiredInputs);

        if (validateRequiredInputs && validateRequiredInputs.message) {
            scrollToMyRefWithOffset(validateRequiredInputs.notValid as ValidationInputsType);
            setInputErrors(validateRequiredInputs.notValid as ValidationInputsType, validateRequiredInputs.message);
            return;
        }

        if (internalCommunication.checked && !internalCommunication.message) {
            scrollToMyRefWithOffset(ValidationInputs.internal_communication as ValidationInputsType);
            setInputErrors(ValidationInputs.internal_communication as ValidationInputsType, "When Internal Communication is checked message field is required");
            return;
        }

        if (embedCommunication.checked && !embedCommunication.message) {
            scrollToMyRefWithOffset(ValidationInputs.embed_communication as ValidationInputsType);
            setInputErrors(ValidationInputs.embed_communication as ValidationInputsType, "When Post-booking email embed is checked message field is required");
            return;
        }

        if (channelCommunication.checked && !channelCommunication.message) {
            scrollToMyRefWithOffset(ValidationInputs.channel_communication as ValidationInputsType);
            setInputErrors(ValidationInputs.channel_communication as ValidationInputsType, "When Channel Visibility is checked message field is required");
            return;
        }

        if (validateConsumerSelected(guestInHome.checked, upcomingArrivals.checked, newBooking.checked)) {
            setErrorMessage("You must select at least one Consumer Segmentation (Guest(s) in Home, Upcoming Arrivals or New Reservations)");
            return;
        }

        for (const consumer of consumersSegmentation) {
            const validateConsumer = validateConsumersFields(consumersSegmentationData[consumer]);
            if (validateConsumer) {
                setConsumerSegmentationElementError({
                  message: validateConsumer,
                  consumer_segmentation: consumer
                })
                return;
            }
        }

        dispatch(setImpactedUnitsDuplicated(false));
        dispatch(setImpactedUnits({
            validUnits: [...impactedUnits],
            invalidUnits: [],
        }));

        if (!isReview) {
            dispatch(setIsReview(true));
            return;
        }

        await dispatch(createBroadcast(broadcastData)).unwrap()
            .then(() => {
                setSuccessMessage("Broadcast created. Messages are being generated to be sent at the corresponding time.");
            })
            .catch((e: { status: number, data: Array<{detail: { error: string, flow_id: string }, status:number}> }) => {
                if (e.data && e.data.length !== 0 && e.data[0].detail.error === 'Error: invalid broadcast reasons') {
                  setErrorMessage("Invalid broadcast reason");
                } else {
                  setErrorMessage("Broadcast creation failed, please try again!");
                }
                
            });
    };

    const handleClickBack = () => {
        window.scrollTo(0, 0);
        dispatch(setIsReview(false));
    };

    //Effects
    useEffect(() => {
        if (guestInHome.checked) {
            setConsumersSegmentation([...consumersSegmentation, CONSUMER_TYPES.GUEST_IN_HOME]);
        } else {
            const consumersFiltered = consumersSegmentation.filter((consumer) => consumer !== CONSUMER_TYPES.GUEST_IN_HOME);
            setConsumersSegmentation(consumersFiltered);
        }
    }, [guestInHome]);

    useEffect(() => {
        if (upcomingArrivals.checked) {
            setConsumersSegmentation([...consumersSegmentation, CONSUMER_TYPES.UPCOMING_ARRIVALS]);
        } else {
            const consumersFiltered = consumersSegmentation.filter((consumer) => consumer !== CONSUMER_TYPES.UPCOMING_ARRIVALS);
            setConsumersSegmentation(consumersFiltered);
        }
    }, [upcomingArrivals]);

    useEffect(() => {
        if (newBooking.checked) {
            setConsumersSegmentation([...consumersSegmentation, CONSUMER_TYPES.NEW_BOOKING]);
        } else {
            const consumersFiltered = consumersSegmentation.filter((consumer) => consumer !== CONSUMER_TYPES.NEW_BOOKING);
            setConsumersSegmentation(consumersFiltered);
        }
    }, [newBooking]);

    useLayoutEffect(() => {
        const getReasons = async () => await dispatch(getBroadcastReasons())?.unwrap();
        getReasons();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!isLoading && readOnly) {
                try {
                    await dispatch(getBroadcastById(broadcastId)).unwrap();
                } catch (error) {
                    setErrorMessage("Error searching for broadcast, please try again!");
                    setTimeout(() => {
                        navigate("/queue")
                    }, timeToRedirectInMilliseconds);
                }
            }
        };

        fetchData();

    }, [readOnly, broadcastId]);

    //Store broadcast detail message
    useEffect(() => {
        if (broadcast) {
            dispatch(
                setMessageType({
                    ...messageType,
                    embed: {
                        message: broadcast.attributes.embed_message,
                        checked: broadcast.attributes.has_embed_message
                    },
                    internal: {
                        message: broadcast.attributes.internal_message,
                        checked: broadcast.attributes.has_internal_message
                    },
                    channel: {
                        message: broadcast.attributes.channel_message,
                        checked: broadcast.attributes.has_channel_message
                    }
                })
            );
        }
    }, [broadcast]);

    useEffect(() => {
      if (isReview) {
        removeAllErrors();
      }
    }, [isReview])

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            // Clean the broadcast and form data when the component is unmounted
            dispatch(cleanBroadcastData());
            dispatch(cleanFormData());
        }
    }, []);

    if (!broadcast && readOnly) {
        return (
            <div className={localStyles.waitSpinner}>
                <Spin size="large">
                    <Alert message="Loading Case Information" description="We are preparing all the information you need." type="info" />
                </Spin>
                <BroadcastInputErrorModal message={errorMessage} onClose={() => {setErrorMessage(null)}} notClose />
            </div>
        );
    }

    const dividerClass = isReview || readOnly ? localStyles.divider : null;

    return (
        <>
            <BackSection
                customWidth="880px"
                title="Vacasa Broadcast Service"
                review=" - Review and Send"
                textButton="Deactivate"
                displayBackButton={true}
                onButtonClick={() => dispatch(setDeactivateModalStatus(true))}
                onBackButtonClick={() => isReview ? handleClickBack() : navigate("/queue")}
                displayButton={broadcast?.attributes?.is_active ? true : false}
            />
            {isSubmitting ?
                <div className={localStyles.creatingBroadcastContainer}>
                    <Spin />
                    <span className={localStyles.creatingBroadcast}>We are creating the broadcast case...please wait</span>
                </div> : null
            }
            <div className={localStyles.container}>
                <div className={localStyles.inner}>
                    <div ref={incidentTitleRef}>
                      <IncidentTitle readOnly={readOnly} />
                      {incidentTitleError ?
                        <Alert
                            style={{marginTop: "15px", borderRadius: "5px"}}
                            type="error"
                            message={incidentTitleError}
                            banner
                        /> : null}
                    </div>
                    <Divider className={dividerClass} />
                    <div ref={impactedUnitsRef}>
                      <ImpactedUnits readOnly={readOnly} />
                      {impactedUnitsError ?
                        <Alert
                            style={{marginTop: "15px", borderRadius: "5px"}}
                            type="error"
                            message={impactedUnitsError}
                            banner
                        /> : null}
                    </div>
                    <Divider className={dividerClass} />
                    <div ref={dateRef}>
                      <DatesEffective readOnly={readOnly} />
                      {dateError ?
                        <Alert
                            style={{marginTop: "15px", borderRadius: "5px"}}
                            type="error"
                            message={dateError}
                            banner
                        /> : null}
                    </div>
                    <Divider className={dividerClass} />
                    <BroadcastReasons readOnly={readOnly} onError={() => {notValidReason()}}/>
                    <Divider className={dividerClass} />
                    <div ref={internalCommunicationRef}>
                      <MessageType
                          title="Internal Communication"
                          subTitle={
                              <span>
                                  Use this space to let other Vacasa employees know what's going on (Admin, Twilio, etc.). It <strong>will not</strong> be visible to Guests.
                              </span>
                          }
                          checkboxMessage="Include internal communication"
                          message={internalCommunication.message}
                          checked={internalCommunication.checked}
                          hideTextInput={!internalCommunication.checked}
                          readOnly={readOnly}
                          onInputChange={(e) => dispatch(setMessageType({...messageType, internal: {message: e?.target?.value, checked: messageType.internal.checked}}))}
                          onCheckboxChange={() => dispatch(setMessageType({...messageType, internal: {message: messageType.internal.message, checked: !internalCommunication.checked}}))}
                      />
                      {internalCommunicationError ?
                        <Alert
                            style={{marginTop: "15px", borderRadius: "5px"}}
                            type="error"
                            message={internalCommunicationError}
                            banner
                        /> : null}
                    </div>
                    <Divider className={dividerClass} />
                    <div ref={embedCommunicationRef}>
                      <MessageType
                          title="Post-booking email embed"
                          subTitle="By selecting this, all post-booking confirmation and check-in emails will have details embedded."
                          checkboxMessage="Embed in post-booking emails"
                          message={embedCommunication.message}
                          checked={embedCommunication.checked}
                          hideTextInput={!embedCommunication.checked}
                          readOnly={readOnly}
                          onInputChange={(e) => dispatch(setMessageType({...messageType, embed: {message: e?.target?.value, checked: messageType.embed.checked}}))}
                          onCheckboxChange={() => dispatch(setMessageType({...messageType, embed: {message: messageType.embed.message, checked: !embedCommunication.checked}}))}
                      />
                      {embedCommunicationError ?
                        <Alert
                            style={{marginTop: "15px", borderRadius: "5px"}}
                            type="error"
                            message={embedCommunicationError}
                            banner
                        /> : null}
                    </div>
                    <div className={localStyles.hide} ref={channelCommunicationRef}>
                      <MessageType
                          title="Channel Visibility"
                          subTitle="If you choose make visible across all listings, the message written below will appear exactly as it is written."
                          checkboxMessage="Make Visible Across all Listing Descriptions"
                          message={channelCommunication.message}
                          checked={channelCommunication.checked}
                          hideTextInput={!channelCommunication.checked}
                          readOnly={readOnly}
                          onInputChange={(e) => dispatch(setMessageType({...messageType, channel: {message: e?.target?.value, checked: messageType.channel.checked}}))}
                          onCheckboxChange={() => dispatch(setMessageType({...messageType, channel: {message: messageType.channel.message, checked: !channelCommunication.checked}}))}
                      />
                      {channelCommunicationError ?
                        <Alert
                            style={{marginTop: "15px", borderRadius: "5px"}}
                            type="error"
                            message={channelCommunicationError}
                            banner
                        /> : null}
                    </div>
                    <Divider className={dividerClass} />
                    <div ref={consumerSegmentationRef}>
                      <CommunicationType readOnly={readOnly} consumerSegmentationError={consumerSegmentationElementError}/>
                      {consumerSegmentationError ?
                        <Alert
                            style={{marginTop: "15px", borderRadius: "5px"}}
                            type="error"
                            message={consumerSegmentationError}
                            banner
                        /> : null}
                    </div>
                </div>
            </div>
            <div className={localStyles.buttonContainer} style={{justifyContent: isReview ? "space-between" : "flex-end"}}>
                {isReview ? <Button text="Back" onClick={handleClickBack} disabled={isSubmitting || broadcastCreatedSuccessFully} /> : null}
                {!readOnly ? <Button text={isReview ? "Send" : "Review"} onClick={handleClick} disabled={isSubmitting || broadcastCreatedSuccessFully} /> : null}

            </div>

            {/* Modals */}
            <DeactivateModal />
            <UpdateDatesModal />
            <BroadcastErrorModal />
            <BroadcastInputErrorModal message={errorMessage} onClose={() => {setErrorMessage(null)}} />
            <BroadcastSuccessModal message={successMessage} onClose={() => {successRedirect()}}/>
        </>
    )
};
