"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitService = void 0;
const client_credentials_service_1 = require("./base/client-credentials.service");
const setup_1 = require("../../setup");
const lodash_1 = require("lodash");
class UnitService {
    constructor() {
        this.apiUrl = process.env.UNIT_SERVICE_URL;
        this.logger = setup_1.LoggingService === null || setup_1.LoggingService === void 0 ? void 0 : setup_1.LoggingService.getLogger("UnitService");
    }
    static getInstance() {
        if (!UnitService.service) {
            UnitService.service = new UnitService();
        }
        return UnitService.service;
    }
    /**
     * Gets Unit
     * @param unitCode unit code
     * @returns
     */
    async getUnitByUnitCode(unitCode) {
        try {
            const params = {
                "filter[unit_code]": unitCode,
            };
            const axios = await client_credentials_service_1.ClientCredentialService.getAxiosInstance();
            const response = await axios.get(`${this.apiUrl}/units`, { params });
            if (response.data.data && response.data.data.length > 0) {
                return response.data.data[0];
            }
            else {
                return null;
            }
        }
        catch (e) {
            this.logger.error("getUnitById error", e);
            throw e;
        }
    }
    /**
     * Gets Active Units by unit_code
     *
     * This method first groups the unit codes considering the limit and then distributes the codes per request
     * into groups considering a maximum of simultaneous calls to Unit Service using Promises.
     *
     * For example:
     *  unitCodes = ["AAA", "BBB", "CCC", "DDD", "EEE", "FFF", "GGG", "HHH", "III", "JJJ", "KKK", "LLL"]
     *
     *  unitCodes organized per request by generateUnitCodesChunks with MAX_UNIT_CODES_PER_REQUEST = 1
     *  [
     *      ["AAA"], ["BBB"], ["CCC"], ["DDD"], ["EEE"], ["FFF"], ["GGG"], ["HHH"], ["III"], ["JJJ"], ["KKK"], ["LLL"]
     *  ]
     *
     *  Then, organized to parallelize with maxRequestsInParallel = 5
     *  [
     *      [["AAA"], ["FFF"], ["KKK"]], // This make a Promise to request sequentially codes in ["AAA"], ["FFF"] and ["KKK"]
     *      [["BBB"], ["GGG"], ["LLL"]], // This make a Promise to request sequentially codes in ["BBB"], ["GGG"] and ["LLL"]
     *      [["CCC"], ["HHH"]], // This make a Promise to request sequentially codes in ["CCC"] and ["HHH"]
     *      [["DDD"], ["III"]], // This make a Promise to request sequentially codes in ["DDD"] and ["III"]
     *      [["EEE"], ["JJJ"]] // This make a Promise to request sequentially codes in ["EEE"] and ["JJJ"]
     *  ],
     *
     *  Finally return all units collected from requests.
     *
     * @param unitCodes unit code
     * @returns
     */
    async getUnitsByUnitCode(unitCodes) {
        try {
            const axios = await client_credentials_service_1.ClientCredentialService.getAxiosInstance();
            const maxRequestsInParallel = 10;
            const codesGroupedPerRequest = this.generateUnitCodesChunks(unitCodes);
            const codesGroupsInParallel = codesGroupedPerRequest.reduce((acc, curr, index) => {
                acc[index % maxRequestsInParallel].push(curr);
                return acc;
            }, [...Array(maxRequestsInParallel).keys()].map(() => ([])));
            const units = [];
            await Promise.all(codesGroupsInParallel.map(async (codeGroups) => {
                for (const requestCodes of codeGroups) {
                    try {
                        if (!(0, lodash_1.isEmpty)(requestCodes)) {
                            const params = {
                                "filter[unit_code][in]": requestCodes.join(','),
                                "page[size]": requestCodes.length
                            };
                            const response = await axios.get(`${this.apiUrl}/units`, { params });
                            units.push(...response.data.data);
                        }
                    }
                    catch (error) {
                        throw error;
                    }
                }
            }));
            return units;
        }
        catch (e) {
            this.logger.error("getUnitsByUnitCode error", e);
            throw e;
        }
    }
    /**
     * Generate chunks of unit codes considering the URL Line size limit and a limit of unit codes per request.
     * The result is an array, where each element inside is an array with all codes per request.
     *
     * For example:
     *  maxCharactersPerRequest = 10;
     *  maxUnitCodesPerRequest = 3
     *
     *  With the unit_codes list
     *  unitCodes = ["AA1", "AAAA11", "BBBBBBB5"]
     *
     *  The result will be:
     *  [
     *      ["AA1", "AAAA11"],  // AA1,AAAA11 = 10 chars <= maxCharactersPerRequest and number of codes = 2 <= maxUnitCodesPerRequest
     *      ["BBBBBBB5"]        // BBBBBBB5 = 8 chars <= maxCharactersPerRequest and number of codes = 1 <= maxUnitCodesPerRequest
     *  ]
     *
     * @param unitCodes string[]
     * @return
     */
    generateUnitCodesChunks(unitCodes) {
        // MAX_CHARACTERS_PER_REQUEST avoids 414 error from Unit Service. Do not increase from 4020.
        const maxCharactersPerRequest = 4020;
        const maxUnitCodesPerRequest = 500;
        return unitCodes
            .reduce((acc, curr) => {
            const lastIndex = acc.length - 1;
            if ([...acc[lastIndex], curr].join(',').length > maxCharactersPerRequest ||
                acc[lastIndex].length + 1 > maxUnitCodesPerRequest)
                acc.push([curr]);
            else
                acc[lastIndex].push(curr);
            return acc;
        }, [[]]);
    }
}
exports.UnitService = UnitService;
